<template>
  <file-page
    v-bind:allowDownload="allowDownload"
    fileType="INFORMATIVOS_MERCADO"
    pageTitle="Informativos de Mercado"
    v-bind:columnList="columnList"
    v-bind:selectVal="selectVal"
  ></file-page>
</template>

<script>
import FilePage from '../../components/filePage/filePage.vue';
import VueCookies from 'vue-cookies';
import VueToast from 'vue-toast-notification';
import Config from '@/config/config';
import Vue from 'vue';

import 'vue-toast-notification/dist/theme-sugar.css';
import 'dayjs/locale/pt-br';

const CONFIG = new Config();

Vue.use(VueCookies);
Vue.use(VueToast);

export default {
  name: 'infoMercadoPage',
  components: {
    FilePage
  },
  data() {
    return {
      allowDownload: true,
      columnList: [
        {
          key: 'ano',
          parentKey: 'mesRef',
          label: 'ANO DE REFERÊNCIA',
          enableFilter: true,
          format: {
            type: 'date',
            pattern: 'YYYY'
          },
          sortingOrder: 'desc'
        },
        {
          key: 'mes',
          parentKey: 'mesRef',
          label: 'MÊS DE REFERÊNCIA',
          enableFilter: true,
          format: {
            type: 'date',
            pattern: 'MMMM'
          },
          sortingOrder: 'month name asc'
        },
        {
          key: 'nomeArquivo',
          label: 'NOME DO ARQUIVO',
          enableFilter: false,
          sortingOrder: 'asc'
        }
      ],
      config: CONFIG,
      selectVal: {}
    };
  }
};
</script>
